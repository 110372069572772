.event-title {
    height: 2.4em;
    /* Adjust based on your line-height */
    line-height: 1.2em;
    /* Adjust based on your font size */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    text-transform: uppercase;
}

.event-description-event-card {
    overflow: hidden;
    height: 7.5em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 1.5em; /* Adjust based on your font size */
    max-height: 7.5em; /* 5 lines * line-height */
}

.reserve-button {
    background-color: transparent;
    /* Matches the background color of the rest of the page */
    border: 2px solid #ff934e;
    /* Border color */
    color: #ff934e;
    /* Text color */
    padding: 10px 20px;
    /* Adjust padding as needed */
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
    margin-top: 5px;
    /* Smooth transition for hover effect */
    font-weight: bold;
    text-transform: uppercase;
}

.reserve-button:hover {
    color: white;
    border-color: white;
    /* Text color on hover */
}