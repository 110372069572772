.transparent-button {
    background-color: transparent;
    border: 2px solid #ff934e;
    /* Adjust border color as needed */
    color: #ff934e;
    /* Adjust text color as needed */
    padding: 10px 20px;
    /* Adjust padding as needed */
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
    /* Smooth transition for hover effect */
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}

.transparent-button:hover {
    color: white;
    /* Text color on hover */
    border-color: white;
    /* Border color on hover */
}

.event-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
    /* Adjust based on your font size */
    /* 5 lines * line-height */
    text-align: justify;
    text-justify: initial;
    margin-top: 20px;
}

.event-name {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 32px;
}

.share-button {
    background-color: var(--accent-color-1);
    aspect-ratio: 1/1;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.share-button:hover {
    color: var(--accent-color);
}

.textured-background {
    background:
        linear-gradient(63deg, #000000 30%, transparent 23%) 7px 0, /* Darker shade of #999 */
        linear-gradient(63deg, transparent 74%, #000000 78%), /* Darker shade of #999 */
        linear-gradient(63deg, transparent 34%, #000000 38%, #000000 58%, transparent 62%), /* Darker shade of #999 */
        #222; /* Darker shade of #444 */
    background-size: 16px 48px;
}