.see-all-events-button {
    background-color: transparent;
    /* Matches the background color of the rest of the page */
    border: 2px solid #ff934e;
    /* Border color */
    color: #ff934e;
    /* Text color */
    padding: 10px 20px;
    /* Adjust padding as needed */
    cursor: pointer;
    /* transition: color 0.3s, border-color 0.3s; */
    margin-top: 5px;
    /* Smooth transition for hover effect */
    font-weight: bold;
    text-transform: uppercase;
    transition: color 0.3s, border-color 0.3s, transform 0.5s ease-in-out;
}

.see-all-events-button:hover {
    color: white;
    border: 2px solid white;
    transform: translateY(-10px);
}

/* .descriptions {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    text-align: justify;
    text-justify: initial;
} */

.welcome-descriptions {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    /* Adjust based on your font size */
    /* 5 lines * line-height */
}

.story-descriptions {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    margin-top: 5px;
    color: white;
}

.banner {
    position: relative;
    height: 90vh;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
}

.banner.fade-out {
    opacity: 0;
}

.footer-link {
    text-decoration: none;
}

.ant-float-btn-body{
    background-color: #fc8439;
}