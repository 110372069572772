.button-submit {
    width: 180;
    border-width: 1px;
    border-color: #ff934e;
    color: #ff934e;
    font-weight: bold;
    border-radius: 0;
}

.button-submit:hover {
    border-color: black;
    color: black;
}

.ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    scrollbar-color: #fc7623 #434343;
}

.event-description-card {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.0em;
    /* Adjust based on your font size */
    /* 5 lines * line-height */
    text-align: justify;
    text-justify: initial;
    margin-top: 10px;
}