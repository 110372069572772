.img-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff934e;
    /* Semi-transparent black background */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.overlay-text{
    text-align: center;
}

.img-container:hover .overlay {
    opacity: 0.9;
}

.order-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-button {
    color: white;
    border-color: white;
    border: solid 2px;
    outline: none;
}

.order-button:hover {
    background-color: white;
    color: #ff934e;
    border-color: #ff934e;
    outline: none;
}